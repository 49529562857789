/*
 * This file contains settings variables used in other included SCSS files.
 */

$viewports: 320px 480px 768px 1024px 1200px;

$viewport-tiny:   nth($viewports, 1);
$viewport-small:  nth($viewports, 2);
$viewport-medium: nth($viewports, 3);
$viewport-large:  nth($viewports, 4);
$viewport-huge:   nth($viewports, 5);

$font-size-base: 3.2vw;
$font-sizes: 3.2vw 2.8vw 2.2vw 1.8vw 1.5vw;
$header-sizes: 3rem 2.44rem 1.95rem 1.56rem 1.25rem 1rem;

/* font faces and sizes */
$body-font-face: "Open Sans", sans-serif;
$heading-font-face: "Titillium Web", sans-serif;
$heading-font-weight: 200;

/* theme colors */
$theme-background-color: #f4f7f7;
$theme-text-color: #1f4e5f;
$theme-footer-color: #79a8a9;
$theme-footer-text-color: #aacfd0;
$theme-primary-color: #79a8a9;
$theme-error-color: #a9a879;
