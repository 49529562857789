.social-icons {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
  margin: 0 auto 1.5rem;

  > li {
    display: inline-block;
    padding: 0 0.5rem;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    > a > img {
      width: 3rem;
      height: 3rem;
    }
  }
}

.contact-form {
  position: relative;

  > .contact-form-input {
    opacity: 1.0;
    transition: opacity 0.5s ease-in-out;
  }

  > .contact-messages {
    @include flex-cover();
    @include overlay(absolute, 99, 0.5s);

    > .contact-message {
      text-align: center;
    }
  }

  &.with-messages {
    > .contact-form-input {
      opacity: 0.3;
    }
  }

  input, textarea, button {
    margin: 0.5rem 0;
  }

  input, textarea {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.25rem 0.75rem;
    width: 100%;
  }

  input, button {
    height: 2rem;
  }

  textarea {
    height: 8rem;
    resize: none;
  }
}

@include breakpoint($viewport-small) {
  .contact-form {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "name message"
      "email message"
      "subject message"
      ". submit";
    grid-column-gap: 1rem;

    .contact-form-name {
      grid-area: name;
    }

    .contact-form-email {
      grid-area: email;
    }

    .contact-form-subject {
      grid-area: subject;
    }

    .contact-form-message {
      grid-area: message;
    }

    .contact-form-submit {
      grid-area: submit;
    }
  }
}
