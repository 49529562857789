/*
 * This file contains theming definitions.
 */

body {
  color: $theme-text-color;
  background-color: $theme-background-color;
}

.landing-page {
  background-image: url('/img/background-pattern.svg');
  background-position: center center;
  background-size: 50rem 50rem;
}

a, a:visited, a:active, a:hover {
  color: $theme-primary-color;
}

h1, h2, h3, h4, h5, h6 {
  color: $theme-text-color;

  > a, > a:visited, > a:active, > a:hover {
    color: $theme-text-color;
  }
}

.home-button {
  .home-icon path {
    stroke: $theme-text-color !important;
  }
}

.loading-indicator {
  @include loading-indicator(2rem, 0.2rem, $theme-primary-color);
}

.menu-overlay {
  background-color: rgba($theme-background-color, 0.9);
}

.menu-button {
  > .burger {
    @include burger(3rem, 0.24rem, 0.8rem, $theme-text-color);
  }

  &.active {
    > .burger {
      @include burger-to-cross;
    }
  }
}

.portrait {
  border-color: $theme-primary-color;
}

.timeline {
  &:before {
    border-color: rgba($theme-primary-color, 0.2);
  }

  > li {
    &:before {
      background-color: $theme-background-color;
      border-color: $theme-primary-color;
    }
  }
}

.contact-form {
  input, textarea {
    background-color: rgba($theme-primary-color, 0.2);
    color: $theme-text-color;
  }
}

button,
.read-more, .read-more:visited, .read-more:active, .read-more:hover,
.call-to-action, .call-to-action:visited, .call-to-action:active, .call-to-action:hover {
  background-color: $theme-primary-color;
  color: $theme-background-color;
}

.social-icons a,
button,
.contact-icon a,
.read-more,
.call-to-action {
  opacity: 0.8;
  transition: opacity 0.5s ease-in-out;

  &:hover, &:active {
    opacity: 1.0;
  }
}

.contact-messages > .contact-message {
  &.contact-message-success {
    color: $theme-primary-color;
  }

  &.contact-message-error {
    color: $theme-error-color;
  }
}

.footer-block-wrapper {
  background-color: $theme-footer-color;

  .footer-block {
    color: $theme-footer-text-color;

    a, a:visited, a:active, a:hover {
      color: $theme-footer-text-color;
    }
  }
}
