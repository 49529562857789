.menu-wrapper {
  @include overlay(absolute, 200, 1s);
}

.menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.menu {
  text-align: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      text-transform: uppercase;

      > a {
        text-decoration: none;
      }
    }
  }
}

.home-button {
  position: absolute;
  display: block;
  top: 2.5rem;
  left: 2.5rem;

  &, > .home-icon {
    width: 3rem;
    height: 3rem;
  }

  &:hover {
    text-decoration: none;
  }
}

.menu-button {
  position: absolute;
  display: block;
  top: 2.5rem;
  right: 2.5rem;
  width: 3rem;
  height: 3rem;
  padding: 0.4rem 0 0 0;
}

.menu-visible {
  overflow: hidden;
}
