body {
   font-family: $body-font-face;
}

.block > .block-heading,
.footer-block {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  display: block;
  font-family: $heading-font-face;
  font-weight: $heading-font-weight;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  > a {
    &, &:visited, &:active, &:hover {
      text-decoration: none;
    }
  }
}

input, textarea, button {
  display: block;
  box-shadow: none;
  font: inherit;
  outline: none;
  border: none;
  padding: none;
}

a {
  &, &:visited, &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

ul, ol {
  padding-left: 1.5rem;
}

.logo-main {
  width: 22rem;
  height: auto;
}

.logo-small {
  width: 12rem;
  height: auto;
}

.screenreader {
  @include invisible-text();
}

.jumbotron-block {
  text-align: center;
  text-transform: uppercase;
}

.head-image {
  width: 10rem;
  height: auto;
  margin-bottom: 0;
}

.excerpt-date {
  text-transform: uppercase;
}
.powered-by-jekyll {
  width: auto;
  height: 2.5em;
  vertical-align: -46%;
}

