/*
 * This file contains grid layout definitions.
 */

/* global susy grid settings */
*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

.top-wrapper {
  position: relative;
  height: 0;
  z-index: 250;
}

.main-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1 0 auto;
  }

  .footer-wrapper {
    flex-shrink: 0;
  }
}

.block {
  padding: 3rem 1rem;

  &.jumbotron-block {
    padding-top: 6rem;
  }

  &.footer-block {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@include breakpoint($viewport-small) {
  .top-wrapper, .block {
    margin: 0 auto;
    max-width: 40rem;
  }
}
