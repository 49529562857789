.timeline {
  position: relative;
  list-style-type: none;
  padding-left: 0;
  padding: 2rem 0;
  margin-bottom: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(1rem - 0.2rem);
    bottom: 0;
    width: 0.4rem;
    border-left: 0.4rem dotted transparent;
  }

  > li {
    clear: both;
    text-align: left;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: relative;
      width: 1rem;
      height: 1rem;
      border: 0.2rem solid transparent;
      border-radius: 50%;
      background: #f4f7f7;
      position: absolute;
      border-radius:50%;
      left: calc(1rem - 0.5rem);
      top: 0.2rem;
      z-index: 99;
    }

    .date, .detail {
      padding-left: 2.5rem;
    }

    .detail {
      .title {
        font-weight: 600;
      }

      .notes {
        list-style-type: disc;
        padding-left: 1.1rem;
      }
    }
  }
}

@include breakpoint($viewport-small) {
  .timeline {
    &:before {
      left: calc(33% + 1rem - 0.2rem);
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    > li {
      &:before {
        left: calc(33% + 1rem - 0.5rem);
      }

      .date {
        float: left;
        width: 33%;
        padding-right: 0.5rem;
        text-align: right;
        position: relative;
      }

      .detail {
        float: right;
        width: 66%;
        padding-left: 3rem;

        .title {
          margin: 0;
        }

        .notes {
          list-style-type: disc;
          padding-left: 1.1rem;
          margin-bottom: 0;
        }
      }

      &:not(:last-child) {
        .detail {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
