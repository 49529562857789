.read-more-container,
.call-to-action-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .read-more, .call-to-action {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.read-more, .call-to-action, button {
  display: block;
  text-transform: uppercase;
  height: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.25rem 0.75rem;
}

