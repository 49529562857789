html {
  font-size: $font-size-base;
}

@include for-every-viewport($font-sizes) using ($size) {
  html {
    font-size: $size;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5rem;
}

p, ol, ul, pre {
  font-size: 1rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}

@for $i from 1 through length($header-sizes) {
  $s: nth($header-sizes, $i);
  h#{$i} {
    font-size: $s;
    line-height: $s;
    margin-block-start: $s;
    margin-block-end: $s*0.4;
  }
}

small {
  font-size: 0.8rem;
}

.menu {
  padding-top: 6rem;

  li {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

footer {
  font-size: 0.8rem;
}

h1.introduction {
  font-size: 1rem;
  line-height: 1rem;

  > .intro {
    margin-bottom: 0;

    &:after {
      content: '\a';
      white-space: pre;
    }

    > .hello {
      font-size: 2rem;
    }
  }

  .name {
    font-size: 3rem;
    line-height: 3rem;
    white-space: nowrap;
  }
}
