@mixin for-every-viewport($arr) {
  @for $i from 1 through length($viewports) {
    $v: nth($viewports, $i);
    $w: nth($arr, $i);
    @include breakpoint($v) {
      @content($w);
    }
  }
}

/*
 * Mixin for centering content horizontally and vertically using flexbox.
 */
@mixin flex-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin overlay($position, $z-index, $duration) {
    position: $position;
    left: 0; top: 0;
    width: 100%; height: 0;
    z-index: $z-index;
    transition: opacity $duration ease-in-out, height 0s linear, visibility 0s linear;
    visibility: hidden;
    overflow: hidden;
    opacity: 0.0;

    &.fade-out {
      transition: opacity $duration ease-in-out, height 0s linear $duration, visibility 0s linear $duration;
    }

    &.visible {
      height: 100%;
      visibility: visible;
      opacity: 1.0;
    }
}

@mixin invisible-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

@mixin loading-indicator($size, $thickness, $color) {
  position: relative;
  width: $size;
  height: $size;
  text-indent: -9999em;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: $thickness solid $color;
    border-color: $color transparent $color transparent;
    animation: loading-indicator 1.2s linear infinite;
  }
}

@keyframes loading-indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
