.skills {
  display: grid;
  list-style-type: none;
  justify-items: center;
  justify-content: center;
  padding: 0;
  margin: 3rem auto;
  grid-column-gap: 1rem;

  grid-template-columns: repeat(6, auto-fit);
  grid-template-areas:
    "s1 s1 s2 s2 s3 s3"
    ". s4 s4 s5 s5 ."
    "s6 s6 s7 s7 s8 s8"
    ". s9 s9 s10 s10 .";

  @include breakpoint($viewport-small) {
    grid-template-columns: repeat(8, auto-fit);
    grid-template-areas:
      "s1 s1 s2 s2 s3 s3 s4 s4"
      ". s5 s5 s6 s6 s7 s7 ."
      ". . s8 s8 s9 s9 . ."
      ". . . s10 s10 . . .";
  }

  > li {
    display: block;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        grid-area: s#{$i};
      }
    }

    > img {
      display: block;
    }

    &, > img {
      width: 5rem;
      height: 5rem;
    }
  }
}

  .skills {

    > .skill {
    }
  }
